export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/gyros-food-truck-logo.png',
    banner_img_root : '/img/world-cafe-banner-2.png',
    resid : '896cbafc8b756ee181489cb5bf6df603',
    key_value : 'gyrosfoodtruck',
    secret_value : 'gyrosfoodtruck',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.3770165066585!2d-104.9956105852067!3d39.75364277944768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c78dc69b91fd1%3A0x2002cb3d067f2eae!2s20th%20St%20%26%20Market%20St%2C%20Denver%2C%20CO%2080202%2C%20USA!5e0!3m2!1sen!2sin!4v1577089605967!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Gyros Food Truck"
};
